import * as React from "react";
import MyVerticallyCenteredModal from "./modal";
import prefabDetail from "../images/prefab-detail.jpg";
import prefabPanels from "../images/prefab-assembly-panels.jpg";

function ModularStructureSection() {

  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  
  return (
    <section className="modular-structure-section">
      <div className="building-overview-header">
        <h2>Modular Structure &amp; MEPS Installations</h2>
        <p>Machine-aided prefabrications can rapidly compute, produce, and numerate various complex physical forms, making complicated forms easily producible that are otherwise impossible or extremely difficult in traditional design approaches.</p>
      </div>
      <div className="">
        <div className="modular-structure">
          <button aria-label="modular-structure-btn-1" onClick={() => setModalShow3(true)} id="modular-structure-btn-1"></button>
          <button aria-label="modular-structure-btn-2" onClick={() => setModalShow4(true)} id="modular-structure-btn-2"></button>
        </div>
      </div>
      <MyVerticallyCenteredModal
        header="Prefab Assembly Panels"
        image={prefabPanels}
        show={modalShow3}
        description="This building's facade is divided into 165 individual transportable pieces manufactured offsite and transported onsite to be assembled by any workers without the need of high-skilled labors."
        onHide={() => setModalShow3(false)}
      />
      <MyVerticallyCenteredModal
        header="Prefab Details"
        image={prefabDetail}
        show={modalShow4}
        description="Different layers improve natural thermal comfort for occupants while reducing mechanical heating/cooling needs."
        onHide={() => setModalShow4(false)}
      />
    </section>
  );
  
};

export default ModularStructureSection;