import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Team from "components/cards/ProfileThreeColGrid.js";
// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/SimpleSubscribeNewsletter";
import Hyperstructure from "components/cards/PortfolioTwoCardsWithImage";
import Footer from "components/footers/MiniCenteredFooter";

export default () => (
  <div>
    <div style={{padding: "2rem"}}>
      <Hero />
    </div>
    <AnimationRevealPage>
      {/* <Features /> */}
      <Hyperstructure />
      <Blog />
      {/* <Team /> */}
      {/* <Testimonial /> */}
      {/* <FAQ /> */}
      <ContactUsForm />
    </AnimationRevealPage>
    <Footer />
  </div>
  
  
);
