import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import ExistingRailway from "images/ExistingRailway.png";
import NewMaglevTrack from "images/NewMaglevTrack.png";
import decouple from "images/decouple.png";
import decouple2 from "images/decouple2.png";
import intermodal from "images/intermodal.png";
import maintenance from "images/maintenance.png";
import maintenance2 from "images/maintenance2.png";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "New System Integration and Backwards Compatibility" }) => {
  return (
    <>
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading className="mb-4">{headingText}</Heading>
            </HeadingRow>
            <Text>

              <p>Last updated: December 9, 2021</p>

              <h1>HYPERTRAIN FLEXIBILITY AND BACKWARDS COMPATIBILITY</h1>
              <p>
                Drive new high-speed Hypertrains on Existing Railway Infrastructure to Increase System Accessibility and Convenience
              </p>
              <img src={ExistingRailway} alt="Existing Railway"></img>
              <h3 style={{textAlign:"center"}}><u>Existing Railway with Contact Propulsion</u></h3>
              <img src={NewMaglevTrack} alt="New Maglev Track"></img>
              <h3 style={{textAlign:"center"}}><u>New Maglev Track with Non-Contact Magnetic Propulsion System</u></h3>
              <br/>
              <br/>
              <h1>HIGH-SPEED DECOUPLING FOR EXISTING AND NEW VEHICLES</h1>
              <img src={decouple} alt="HIGH-SPEED DECOUPLING FOR EXISTING AND NEW VEHICLES"></img>
              <h1>HYPERTRANSIT FLEXIBILITY AND HIGH-SPEED DECOUPLING</h1>
              <img src={decouple2} alt="HYPERTRANSIT FLEXIBILITY AND HIGH-SPEED DECOUPLING"></img>
              <h1>HYPERTRANSIT INTERMODAL ONLINE CHECK-IN SYSTEM</h1>
              <img src={intermodal} alt="HYPERTRANSIT INTERMODAL ONLINE CHECK-IN SYSTEM"></img>
              <h1>CONSTRUCTION AND MAINTENANCE</h1>
              <img src={maintenance} alt="CONSTRUCTION AND MAINTENANCE"></img>
              <p>
                Bridge builder machines can be designed to assemble and replace prefabricated sections of Maglev track and Hyperway tube sections. This machine can also possibly assist in the construction of the columns and transportation of these prefab elements.
              </p>
              <p>
                Other maintenance machines can be designed to replace, maintain, or clean the solar panels, vacuum pumps, electrical components, or other elements of the Hyperway.
              </p>
              <p>
                Specifically, the Maglev track can be designed to be plugged-in to this elevated path and then replaced or upgraded with the Hyperway tube and solar panels.
              </p>
              <img src={maintenance2} alt="CONSTRUCTION AND MAINTENANCE"></img>
              
            </Text>
          </ContentWithPaddingXl>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
