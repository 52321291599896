import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
// import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import allPreview from "../images/all-preview.png";
import station from "../images/station.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-4/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-4/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm `;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-128 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

// const CardMetaFeature = styled.div`
//   ${tw`flex items-center mt-4 mr-4 last:mr-0`}
//   svg {
//     ${tw`w-5 h-5 mr-1`}
//   }
// `;
// const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

export default ({
  subheading = "Hyperstructures",
  headingHtmlComponent = (
    <>
      We've done some <span tw="text-primary-500" style={{color: "#04A0D2"}}>Hyperway infrastructure.</span>
    </>
  ),
  description = "Create a series of sustainable, walkable low to medium rise micro-cities on affordable land inter-connected with high-speed Hyperway infrastructure. Sell the lifestyle of not needing to own a vehicle, of walking to get groceries or to go to go be in nature or take a 40 min trip to Montreal from outside of Toronto for work or school or to travel or visit family.",
  linkText = "View all details",
  cardLinkText = "Go to the section",
  textOnLeft = true
}) => {
  const cards = [
    {
      imageSrc: allPreview,
      company: "Building Breakdown",
      title: "Explore each layer of the sustainable infrastructure",
      // durationText: "90 Days Campaign",
      // locationText: "New York"
      // link: "/hyperstructure#site-plan"
    },
    {
      imageSrc: station,
      company: "Site Plan",
      // type: "SEO Marketing",
      title: "Check out our general plan for the whole site",
      // durationText: "180 Day Campaign",
      // locationText: "Palo Alto"
      // link: "/hyperstructure#bb-section"
    }
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading style={{color: "#04A0D2"}}>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink href="/hyperstructure" style={{color: "#337AB7"}}>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany style={{color: "#04A0D2"}}>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    {/* <CardMetaFeature>
                      <TimeIcon /> {card.durationText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.locationText}
                    </CardMetaFeature> */}
                  </CardMeta>
                  {/* <a href={card.link}>
                    <CardAction>{cardLinkText} </CardAction>
                  </a> */}
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
