// map.js
import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "mapbox-gl"
// import bbox from "@turf/bbox"
// import { multiPoint } from "@turf/helpers"
// import Markers from "./markers"
import "mapbox-gl/dist/mapbox-gl.css"

const MAPBOX_TOKEN = "pk.eyJ1IjoieW1hcnQxbiIsImEiOiJja3B1dHgwd2IwdW5lMnZxdm5nNnVobTJvIn0.NY4J0z1prgE4pPpXbCEFrw"

var bounds = [
  [-81.41591122443094,42.58205446624885], // Southwest coordinates
  [-77.57218204015942,45.73881332406975] // Northeast coordinates
];

const mapContainerStyle = {
  width: "100%",
  height: "30vmax",
  maxWidth: "1700px",
  maxHeight: "500px",
  marginLeft: "auto",
  marginRight: "auto"
}

// // on top of map.js
// const places = [
//   {
//     name: "PROJECT LOCATION",
//     longitude: -79.52311727112959,
//     latitude: 43.783300381275495,
//   },

//   {
//     name: "Timber Systems Limited",
//     longitude: -79.2711232711813,
//     latitude: 43.87776893254474,
//   },
//   {
//     name: "Walters Group Inc.",
//     longitude: -79.83500300000588,
//     latitude: 43.187843443650166,
//   },
//   {
//     name: "OSCO Construction Group",
//     longitude: -79.73912227663675,
//     latitude: 43.69941317189614,
//   },
//   {
//     name: "Lightweight Concrete Solution Inc.",
//     longitude: -79.51668187310554,
//     latitude: 43.61473032375986,
//   },
//   {
//     name: "Catalyst Offsite Inc.",
//     longitude: -79.4773266257007,
//     latitude: 43.669187437897165,
//   },
//   {
//     name: "Skyrise Prefab Building Solutions Inc.",
//     longitude: -79.06562687321872,
//     latitude: 43.825691795282495,
//   },

// ]

const Map = () => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      // MAP STYLE URL HERE!
      style: "mapbox://styles/mapbox/light-v10",
      // Empire State Building [lng, lat]
      center: [-79.52311727112959,43.783300381275495],
      zoom: 9,
      maxBounds: bounds
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.on('load', function () {
      map.addSource('route', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.52311727112959,43.783300381275495],
                  [-79.52203411281391,43.78350085762585],
                  [-79.52293652969684,43.78577674555942],
                  [-79.55782387533066,43.77904852181695],
                  [-79.63870818180789,43.7524392187955],
                  [-79.67311761859094,43.728269636334176],
                  [-79.67574611723757,43.711172399562486],
                  [-79.69920663284955,43.66886518275652],
                  [-79.76173466369062,43.61789277059137],
                  [-79.7776725992992,43.61299750462575],
                  [-79.79813096984321,43.5995825372803],
                  [-79.80972219573937,43.59270364722951],
                  [-79.81049035783019,43.586871899278805],
                  [-79.7265894734204,43.52755302730566],
                  [-79.75818360410369,43.499041004763455],
                  [-79.7638111024454,43.48456634217948],
                  [-79.78347775074621,43.47014023758467],
                  [-79.78939064032159,43.445207423989615],
                  [-79.83241142721687,43.40120037533171],
                  [-79.83053613192266,43.33665794098192],
                  [-79.80546818479365,43.31321128871764],
                  [-79.76200636554313,43.2484730012861],
                  [-79.79631253517687,43.2237174679324],
                  [-79.81240108483229,43.198872541787125],
                  [-79.81988783566248,43.1963178167893],
                  [-79.82657812364374,43.19864029847702],
                  [-79.82939336070177,43.19644875034464],
                  [-79.83298181578823,43.18778028256037],
                  [-79.83500300000588,43.187843443650166]
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.69920663284955,43.66886518275652],
                  [-79.7076234167609,43.68213792862957],
                  [-79.73266030266905,43.701617595236655],
                  [-79.73623714203273,43.70125873906187],
                  [-79.73912227663675,43.69941317189614]
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.51668187310554, 43.61473032375986],
                  [-79.51991496202169,43.619012349123125],
                  [-79.5493720109663,43.61362347579333],
                  [-79.57378362828547,43.66875663126609],
                  [-79.57820617890343,43.67584821477891],
                  [-79.55400372730918,43.709147560960815],
                  [-79.52056815334709,43.716428811098865],
                  [-79.53480254606501,43.77265137672853],
                  [-79.5217178229517,43.77561729149801],
                  [-79.52087091530831,43.77839961389856],
                  [-79.52201424061934,43.783383010592615],
                  [-79.52311727112959,43.783300381275495]
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.4773266257007, 43.669187437897165],
                  [-79.47798688615421,43.670258377634184],
                  [-79.46763081317135,43.67247921833524],
                  [-79.46945657658023,43.67412449946596],
                  [-79.47035449300995,43.675834678082765],
                  [-79.4801584709038,43.68311781412905],
                  [-79.48798589923513,43.697250712021855],
                  [-79.48887585689027,43.70146216913747],
                  [-79.50551588017305,43.711035784196326],
                  [-79.52087091530831,43.77839961389856],
                  [-79.52201424061934,43.783383010592615],
                  [-79.52311727112959,43.783300381275495]
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.06562687321872, 43.8256917952824955],
                  [-79.06439916655131,43.82594160566592],
                  [-79.09816897220355,43.90481136016109],
                  [-79.09517870596807,43.914627268686615],
                  [-79.09805822161991,43.91805778984241],
                  [-79.17953402695457,43.89663082026061],
                  [-79.19708612548675,43.87960691488345],
                  [-79.24766532565343,43.86645212103093],
                  [-79.26997453498092,43.858882708545735],
                  [-79.3924931364661,43.83609342470709],
                  [-79.41556454810973,43.83897540678032],
                  [-79.42910822483208,43.83369726712559],
                  [-79.47571824840622,43.819556959688214],
                  [-79.48283694823749,43.80052160440093],
                  [-79.4919429179617,43.79367012968606],
                  [-79.52305291724178,43.78577350196673],
                  [-79.52203411281391,43.78350085762585],
                  [-79.52311727112959,43.783300381275495]
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.2709697290519,43.877395736364946],
                  [-79.26484829583177,43.87890786113374],
                  [-79.26321109286692,43.88080573374805],
                  [-79.26155419998867,43.881242478822685],
                  [-79.25805829985012,43.8654396202013],
                  [-79.26014189603848,43.86237253175631],
                  [-79.26997453498092,43.858882708545735]
                ]
              }
            },
            {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  [-79.81049035783019,43.586871899278805],
                  [-79.86210924431208,43.542368922461094],
                  [-79.88277100348205,43.537398477818044],
                  [-79.90544408433385,43.527854075492115],
                  [-79.92901927007244,43.52414785635261],
                  [-79.95068669007651,43.511153638903465],
                  [-79.9620232305025,43.49782503005369],
                  [-79.98109854253221,43.494540683514884],
                  [-80.00520028340821,43.48180933067147],
                  [-80.04024678487605,43.472367019672674],
                  [-80.06160983160925,43.472006476813334],
                  [-80.1180811411624,43.45626067410774],
                  [-80.13499955974498,43.44962585856243],
                  [-80.16362940054239,43.449706433280795],
                  [-80.23931089164205,43.43599915636253],
                  [-80.30476326545488,43.414543277662546],
                  [-80.3558077003147,43.413493313607376],
                  [-80.40444046237968,43.387235105301755],
                  [-80.41453020011124,43.36736974731534],
                  [-80.41741144689257,43.34731650415236],
                  [-80.4678313470207,43.31904479906365],
                  [-80.48654052287179,43.298692916697036],
                  [-80.49761388029992,43.29376584222953],
                  [-80.53070981090815,43.273698865535124],
                  [-80.55278212277386,43.266811086798185],
                  [-80.56663955163515,43.256659255289975],
                  [-80.57530628278221,43.24103073786665],
                  [-80.59787558726339,43.2338785202092],
                  [-80.60731361509073,43.21293995172897],
                  [-80.62939859215709,43.201340375428686],
                  [-80.64885338227447,43.17808203613424],
                  [-80.67581004544832,43.15569666892256],
                  [-80.68489435959583,43.13398201253713],
                  [-80.68969143089885,43.12677265915758],
                  [-80.70217400629832,43.11979316584518],
                  [-80.72054350862015,43.11638670324831],
                  [-80.74427853680953,43.10626952568683],
                  [-80.92167250563085,42.98065009516398],
                  [-81.09457368468338,42.95292936809241],
                  [-81.11351510598263,42.94404187612443],
                  [-81.17817635517486,42.936824554348476],
                  [-81.157439439533,42.85714204540221],
                  [-81.15049114641226,42.85144909313843],
                  [-81.15025660073263,42.80445443493403],
                  [-81.15152342462397,42.804244420777394]
                ]
              }
            },
          ]
        }
      });
      map.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#555',
          'line-width': 5
        }
      });
    });

    setMap(map)

    return () => map.remove()
  }, [])

  useEffect(() => {
    if (!map) return

    var el = document.createElement('div');
    el.className = 'project-location';

    new mapboxgl.Marker(el)
      .setLngLat([-79.52311727112959, 43.783300381275495])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>PROJECT LOCATION</h5><p>7332 Jane Street</p>'))
      .addTo(map);

    el = document.createElement('div');
    el.className = 'tube-steel';
    new mapboxgl.Marker(el)
      .setLngLat([-79.83500300000588, 43.187843443650166])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>Walters Group Inc.</h5><p>Hyperloop tube steel sections</br>1318 Rymal Road East, Hamilton</br>89.4 km from site (~1 hr)</p>'))
      .addTo(map);
    
    el = document.createElement('div');
    el.className = 'light-concrete';
    new mapboxgl.Marker(el)
      .setLngLat([-79.73912227663675, 43.69941317189614])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>OSCO Construction Group</h5><p>Precast concrete foundation & slabs</br>35 Rutherford Road South, Brampton</br>31.2 km from site</p>'))
      .addTo(map);

    el = document.createElement('div');
    el.className = 'light-concrete';
    new mapboxgl.Marker(el)
      .setLngLat([-79.51668187310554, 43.61473032375986])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>Lightweight Concrete Solution Inc.</h5><p>Sprayed light concrete surfaces</br>55 Horner Ave #2, Etobicoke</br>27.1km from site (~19 min)</p>'))
      .addTo(map);

    el = document.createElement('div');
    el.className = 'roofing';
    new mapboxgl.Marker(el)
      .setLngLat([-79.4773266257007, 43.669187437897165])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>Catalyst Offsite Inc.</h5><p>Prefab roofing panels, MEPs, electric, mechanic components, prefab green roof mudules</br>125 Ryding Avenue, Toronto</br>19 km from site (~24 min)</p>'))
      .addTo(map);

    el = document.createElement('div');
    el.className = 'Skyrise-Prefab';
    new mapboxgl.Marker(el)
      .setLngLat([-79.06562687321872, 43.825691795282495])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>Skyrise Prefab Building Solutions Inc.</h5><p>Prefab roofing panels</br>896 Brock Rd #1, Pickering</br>51.1 km from site (34 min)</p>'))
      .addTo(map);

    el = document.createElement('div');
    el.className = 'timber';
    new mapboxgl.Marker(el)
      .setLngLat([-79.2711232711813, 43.87776893254474])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>Timber Systems Limited</h5><p>Mass timber slabs & structure</br>120 Bullock Dr, Markham</br>16.1 km from site (~20 min)</p>'))
      .addTo(map);

    el = document.createElement('div');
    el.className = 'black-circle';
    new mapboxgl.Marker(el)
      .setLngLat([-81.15152342462397,42.804244420777394])
      .setPopup(new mapboxgl.Popup({ offset: 20 }) // add popups
        .setHTML('<h5>Element5 Limited Partnership</h5><p>Mass timber slabs & structure</br>70 Dennis Road, St. Thomas</br>191 km from site (~2.5 hrs)</p>'))
      .addTo(map);

    // map.fitBounds(
    //   [
    //     [zero, one],
    //     [two, three],
    //   ],
    //   {
    //     padding: 20,
    //     maxZoom: 10,
    //     duration: 2000,
    //   }
    // )

    // if (places.length !== 0) {
    //   const coords = []
    //   places.forEach(place => {
    //     coords.push([place.longitude, place.latitude])
    //   })
    //   const feature = multiPoint(coords)
    //   const box = bbox(feature)

    //   map.fitBounds(
    //     [
    //       [box[0], box[1]],
    //       [box[2], box[3]],
    //     ],
    //     {
    //       padding: 20,
    //       maxZoom: 10,
    //       duration: 2000,
    //     }
    //   )
    // } else {
    //   map.easeTo({
    //     center: [-79.46503704833691,43.52591704003686],
    //     zoom: 10,
    //     duration: 2000,
    //   })
    // }
  }, [map])

  return (
    <div ref={mapContainerRef} rel="noopener" style={mapContainerStyle}>
      {/* {places && map && <Markers map={map} places={places} />} */}
    </div>
  )
}

export default Map