import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import landing_video from "../../images/hc-landing-video.mp4";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "../../images/hc-light-logo.png";

import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-12 mx-auto h-full flex flex-col`;
const Content = tw.div`sm:px-12 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const lightLogoLink = (
  <LogoLink href="/">
    <img src={logo} alt="logo" />
    HYPERTRANSIT CANADA
  </LogoLink>
);

const PrimaryAction = tw.button`rounded px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-blue-700 text-gray-100 hocus:bg-blue-800 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <video autoPlay loop muted id='video' style={{position: "absolute", zIndex: "-1", width: "100%", height: "100vh", overflow: "hidden", top: "0", left: "0", objectFit: "cover"}}>
        <source src={landing_video} type='video/mp4'></source>
      </video>
      <HeroContainer style={{padding:"2rem"}}>
        <section>
          <StyledHeader style={{backgroundColor:"transparent"}} logoLink={lightLogoLink} />
        </section>
        <Content>
          <Heading>
              Advance the Development of North American Transportation Infrastructure
          </Heading>
          <PrimaryAction>Watch demo</PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
