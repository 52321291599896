import * as React from "react";
import MyVerticallyCenteredModal from "./modal";
import interior1 from "../images/interior-1.jpg";
import interior2 from "../images/interior-2.jpg";

function BuildingOverviewSection() {

  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  
  return (
    <section className="building-overview-section">
      <div className="building-overview-header">
        <h2>Building Overview Renders &amp; Illustrations</h2>
        <p>The building's interior space can be leased to local businesses for recreational, retail &amp; commercial, and residential functions, thus human comfort and environmental-friendliness is prioritized. Press the camera icons to get interior views and details.</p>
      </div>
      <div className="">
        <div className="building-overview">
          <button aria-label="building-overview-btn-1" onClick={() => setModalShow1(true)} id="building-overview-btn-1"></button>
          <button aria-label="building-overview-btn-2" onClick={() => setModalShow2(true)} id="building-overview-btn-2"></button>
        </div>
      </div>
      <MyVerticallyCenteredModal
        header="Rendered Interior View I"
        image={interior1}
        show={modalShow1}
        description="Natural lighting and sustainably harvest local timbers help improve the inhabitants' wellbeing and interior views. 30~40% of building spaces being underground also help insulate the interior spaces passively."
        onHide={() => setModalShow1(false)}
      />
      <MyVerticallyCenteredModal
        header="Rendered Interior View II"
        image={interior2}
        show={modalShow2}
        description="Natural lighting and sustainably harvest local timbers help improve the inhabitants' wellbeing and interior views. 30~40% of building spaces being underground also help insulate the interior spaces passively."
        onHide={() => setModalShow2(false)}
      />
    </section>
  );
  
};

export default BuildingOverviewSection;



      