import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import bizcase from "images/bizcase.png";
import bizcase2 from "images/bizcase2.png";
import bizcase3 from "images/bizcase3.png";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
// const DetailButton = tw(PrimaryButtonBase)`inline-block mt-2`

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-16 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-blue-700 hocus:text-blue-700
`;

export const DetailButton = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-blue-600 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export default ({ headingText = "The Business Case" }) => {
  return (
    <>
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading className="mb-4">{headingText}</Heading>
            </HeadingRow>
            <Text>
              <p>Last updated: December 9, 2021</p>
              <h1>HYPERTRANSIT SYSTEM</h1>
              <img src={bizcase} alt="business case"></img>
              <p style={{textAlign:"center"}}><strong>Tokyo Metro System</strong></p>
              <p>
                Japan’s three largest Metropolitan areas, Tokyo, Nagoya, and Osaka have likely the most efficient metro systems in the world with the Tokaido Shinkansen high-speed line having operated for 50 years without a single derailment or collision. These metro lines are privately owned and their construction is paid for through the development of residential and commercial mid and high-rises with low-interest construction loans on land nearby the metro stations.
              </p>
              <p>source: <a target="_blank" href="http://www.demap.info/tetsudonow/" rel="noreferrer">http://www.demap.info/tetsudonow/</a></p>
              
              <div className="col-12 mt-5" style={{border:"1px dashed gray", borderColor:"rgba(229, 231, 235, var(--tw-border-opacity)"}}></div>

              <img src={bizcase2} alt="business case 2"></img>
              <p>
                Canada’s population grows by a staggering 1.7% per year with 71.8% of Canadians living in a Census Metropolitan Area, compared to the United States’s population growth of 0.4%.
              </p>
              <p>
                Based on the current tax rates in Canada and Ontario, in order to get by in Toronto:
              </p>
              <p>
                Renters who take public transit will need to earn $55,500 before tax ($42,584 after tax) <br/> Renters who drive will need to earn $61,000 before tax ($46,376 after tax) <br/> Homeowners who take public transit will need to earn $88,000 before tax ($65,056 after tax) <br/> Homeowners who drive will need to earn $94,000 before tax ($68,971 after tax)
              </p>
              <p>
                Toronto and Montreal are both currently experiencing record-high population losses to nearby cities pointing to a demand in affordable housing and access to opportunities in the city.
              </p>
              <p>source: <br/><a target="_blank" href="https://www150.statcan.gc.ca/n1/daily-quotidien/210114/dq210114a-eng.htm" rel="noreferrer">https://www150.statcan.gc.ca/n1/daily-quotidien/210114/dq210114a-eng.htm</a><br/><a target="_blank" href="https://www.newswire.ca/news-releases/cost-of-living-index-shows-how-expensive-is-it-to-live-in-toronto-in-2020-816959756.html" rel="noreferrer">https://www.newswire.ca/news-releases/cost-of-living-index-shows-how-expensive-is-it-to-live-in-toronto-in-2020-816959756.html</a></p>
          
              <h1>HYPERSTRUCTURES</h1>
              <p>
                Create a series of sustainable, walkable low to medium rise micro-cities on affordable land inter- connected with high-speed Hyperway infrastructure. Sell the lifestyle of not needing to own a vehicle, of walking to get groceries or to go to go be in nature or take a 40 min trip to Montreal from outside of Toronto for work or school or to travel or visit family.
              </p>
              <img src={bizcase3} alt="business case 3"></img>
              <div className="col-12" style={{textAlign:"center"}}>
                <DetailButton href="/hyperstructure">View Details</DetailButton>
              </div>
            </Text>
          </ContentWithPaddingXl>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
