import * as React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hyperstructure.css';
import { useEffect, useState } from "react";
// import Layout from "../components/layout"
import { Jumbotron } from "react-bootstrap";
import jumbotronBG from "../images/jumbotron-blurred.jpg";
import Map from "../components/map";
import station2 from "../images/station2.jpg"
import elevation from "../images/elevation.jpg"
import Expand from "../components/expand";
import Popovers from "../components/popover";
import Footer from "components/footers/MiniCenteredFooter";
import MyCarousel from "../components/carousel";
import BBSection from "../components/BBSection";
// import BBSectionImage from "../components/BBSectionImage";
import ModularStructureSection from "../components/ModularStructureSection";
import BuildingOverviewSection from "../components/BuildingOverviewSection";
import GRSection from "../components/GreenRoofSection";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import styled from "styled-components";
import tw from "twin.macro";
import logo from "../../images/hc-light-logo.png";
import AnimationRevealPage from "helpers/AnimationRevealPage.js"

const isBrowser = typeof window !== "undefined";

const StyledHeader = styled(Header)`
  ${tw`w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const lightLogoLink = (
  <LogoLink href="/">
    <img src={logo} alt="logo" />
    HYPERTRANSIT CANADA
  </LogoLink>
);

// markup
const Hyperstructure = () => {

  // eslint-disable-next-line
  const [BB, setBB] = useState("");

  useEffect(()=>{

    if (isBrowser) {
      setBB("active");

      const BBSection = document.querySelector('.BB-section');

      const sectionHeight = BBSection.getBoundingClientRect().height;

      const html = document.documentElement;

      document.addEventListener('scroll', (e) => {
        let scrolled = html.scrollTop / (sectionHeight - html.clientHeight);
        console.log(`scrolled: ${scrolled}`);

        BBSection.style.setProperty('--part-1', calculateOpacity(0.47783779159706197, 0.561482217242633, scrolled))
        BBSection.style.setProperty('--part-2', calculateOpacity(0.6053575205130826, 0.7097609717961453, scrolled))
        BBSection.style.setProperty('--part-3', calculateOpacity(0.7502144576538213, 0.8561387166758945, scrolled))
        BBSection.style.setProperty('--part-4', calculateOpacity(0.9288333266005907, 1.0003112903340787, scrolled))
        BBSection.style.setProperty('--part-5', calculateOpacity(1.0518666726865307, 1.1444078236053123, scrolled))
        BBSection.style.setProperty('--ending', calculateOpacity(1.1831884209500771, 1.2894928819069027, scrolled))
      })

      function calculateOpacity(start, end, percent) {
        if (percent - start < 0) return 0
        if (percent - end > 0) return 1

        return (percent - start) / (end - start)
      }

    } else setBB("")

  }, [])

  return (
    <div>
      <AnimationRevealPage className="p-0">
        <Jumbotron fluid className="jumbotron" style={{ backgroundImage: `url(${jumbotronBG})`, backgroundSize: 'cover', padding:"2rem" }}>
          <section>
            <StyledHeader style={{backgroundColor:"transparent"}} logoLink={lightLogoLink} />
          </section>
          <div className="container w-100">
            <h1 className="heading">Hyperstructure Design Team</h1>
            <Expand text="Our Vision: Help push the agenda of an environmentally regenerative, economically profitable, and socially responsible hyperloop infrastructure system as an integrated regional developmental solution for Canada, by means of visualizations grounded in reality."/>
          </div>
        </Jumbotron>
        {/* <div className="dividing" style={{ backgroundColor:"rgb(36,43,46)"}}></div> */}
        <section className="station-render-section">
          <div className="station-header">
            <h1>Station Plan</h1>
            <p>We analyzed the expected traffic circulations at the site. We seek to maximize the efficiency of the traffic flows while mimicking the flow of waterways, which crisscross the Greater Toronto Area's existing landscape. It also includes a map showcasing the supplier and offsite construction locations involved.</p>
          </div>
          <MyCarousel />
        </section>
        {/* <div className="dividing" style={{ backgroundColor:"rgb(30,30,30)"}}></div> */}
        <section className="station-section" style={{ padding:"20px 10px 0px 10px" }}>
          <img src={elevation} alt="elevation" id="elevation" style={{ margin:"10px 0" }}></img>
          <p style={{ marginTop: '10px', marginBottom: '0px', opacity:0.92 }}>Elevation A</p>
        </section>
        <section className="station-section" id="site-plan" style={{ paddingTop:"8px" }}>
          <div className="building-header" style={{ paddingTop: "36px" }}>
            <h1 style={{ marginBottom:"6px" }}>Site Plan</h1>
            <p>Press on the button to get more details of site designs.</p>
          </div>
          <div className="station1">
            <Popovers title="Parametrically Prefabricated Building Design" content={<p style={{ margin:"0" }}>The overall mixed development project centered around the hyperloop station...<br/><a href='#ppbd'>For more detail</a></p>} btnId="popover-btn1"/>
            <Popovers title="Modular Prefabricated Green Roof Designs" content={<p style={{ margin:"0" }}>The easily-producible hexagon module using salvaged materials such as city recyclables and wood byproducts can be completed by community volunteering efforts...<br/><a href='#mpgrd'>For more detail</a></p>} btnId="popover-btn2"/>
            <Popovers title="Modular Prefabricated Green Roof Designs" content={<p style={{ margin:"0" }}>The easily-producible hexagon module using salvaged materials such as city recyclables and wood byproducts can be completed by community volunteering efforts...<br/><a href='#mpgrd'>For more detail</a></p>} btnId="popover-btn3"/>
          </div>
        </section>
        <div className="building-overview-header" style={{ paddingTop:"0px", paddingBottom:"0px" }}>
          <h2>Station Program Plan</h2>
          <p>Analyzed expected traffic circulations at the site. Seeking to maximize the efficiency of the traffic flows while mimicking the flow of waterways, which crisscross the Greater Toronto Area's existing landscape.</p>
        </div>
        <section className="station-section">
          <div className="station2-section">
            <img src={station2} className="station2" alt="station map 2"></img>
          </div>
          <div className="building-overview-header" style={{ paddingTop:"30px", paddingBottom:"15px" }}>
            <h2>Map</h2>
            <p>The map showcasing the supplier and offsite construction locations involved. Press the magnifier icons to navigate and get more details.</p>
          </div>
          <Map />
        </section>
        <div className="dividing"></div>
        <div className="building-header" id="ppbd" style={{ paddingTop:"60px" }}>
          <h1>Parametrically Prefabricated Building Design</h1>
          <p>This building is part of the overall mixed development project centered around the hyperloop station. It seeks to improve human comfort &amp; social interactions and to help build and boost a greener local economy through sustainable building design approaches.</p>
        </div>
        <BuildingOverviewSection />
        <ModularStructureSection />
        {/* <div className="d-none d-sm-block"> */}
      </AnimationRevealPage>

      <BBSection />
      <div className="dividing"></div>
      
      {/* <AnimationRevealPage> */}
        {/* </div>
        <div className="d-sm-none">
          <BBSectionImage />
        </div> */}
        <GRSection />
        {/* <div className="dividing"></div> */}
      {/* </AnimationRevealPage> */}
        {/* <div className="px-4"> */}
          <Footer />
        {/* </div> */}
      {/* </AnimationRevealPage> */}
    </div>
    
  // </main>
  )
}

export default Hyperstructure
