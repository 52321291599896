import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import GRDExistingInfras from "images/GRDExistingInfras.png";
import GRDSocialEquity from "images/GRDSocialEquity.png";
import GRDEnvEquity from "images/GRDEnvEquity.png";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Generative Regional Design - Different Aspects" }) => {
  return (
    <>
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading className="mb-4 w-100" style={{textAlign:"center"}}>{headingText}</Heading>
            </HeadingRow>
            <Text>

              <p>Last updated: December 9, 2021</p>

              <h1>GENERATIVE REGIONAL DESIGN - EXISTING INFRASTRUCTURE</h1>
              <img src={GRDExistingInfras} alt="GENERATIVE REGIONAL DESIGN - EXISTING INFRASTRUCTURE"></img>
              
              <h1>GENERATIVE REGIONAL DESIGN - SOCIAL EQUITY</h1>
              <img src={GRDSocialEquity} alt="GENERATIVE REGIONAL DESIGN - SOCIAL EQUITY"></img>

              <h1>GENERATIVE REGIONAL DESIGN - ENVIRONMENTAL EQUITY</h1>
              <img src={GRDEnvEquity} alt="GENERATIVE REGIONAL DESIGN - ENVIRONMENTAL EQUITY"></img>
              
              <h1>GENERATIVE REGIONAL DESIGN - FACTORS</h1>
              <h5>Dynamic iterative system for developing multi-goal path optimization with projection outputs</h5>
              <br/>
              <p>
                <u>For route generation, assign fitness biases and goals for:</u>
              </p>
              <p>
                DIRECT NEGATIVE ENVIRONMENTAL IMPACT - Generate projections for damaging values to different environments when building and maintaining the infrastructure
              </p>
              <p>
                INDIRECT NEGATIVE ENVIRONMENTAL IMPACT - Dynamically calculate embodied carbon and GHG emissions after using w column height creating x volume of y number of columns using z materials
              </p>
              <p>
                DIRECT POSITIVE ENVIRONMENTAL IMPACT - Generate projections for area and severity of Brownfield sites or damaged environments that can be directly environmentally regenerated
              </p>
              <p>
                INDIRECT POSITIVE ENVIRONMENTAL IMPACT - Generate projections for reduced emissions of different types of transpor- tation modes and for renewable power generation
              </p>
              <p>
                SOCIAL - Generate projections for populations of communities benefitted by increased accessibility to this transportation infrastructure - generate projections for different station locations
              </p>
              <p>
                FINANCIAL - Generate <strong>dynamic travel demand model</strong> according to different geographic options as well as stepped-up cost projections, operation and maintenance cost projections, and revenue projections
              </p>
              <p>
                PHYSICAL - Simulate the physical interactions of different route options with the built and natural landscape, including underground geotechnical factors - depends on design of Hyperway + construction, maintenance, and operability
              </p>
              <p>
                Develop calculations first by hand then integrate them into system <br/> Create diagram of dynamic travel demand model illustrating where we’ll get more accurate data in the future, make precedent studies of existing models and study existing transportation proposals <br/> Create stakeholder outreach programs including surveys
              </p>
            </Text>
          </ContentWithPaddingXl>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
