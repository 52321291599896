import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
// import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import topBlogImage from "images/topblog.png";
import regionalDesign from "images/regionalDesign.png";
import bizcase from "images/bizcase.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pt-10 pb-32 lg:pb-40`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded`
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-blue-800`;

// const DecoratorBlob1 = tw(
//   SvgDecoratorBlob1
// )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
// const DecoratorBlob2 = tw(
//   SvgDecoratorBlob2
// )`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc: topBlogImage,
      category: "APPLES TO APPLES COMPARISON",
      title: "Existing Infrastructure and Transportation Technology",
      url: "/blog/ExistingInfraAndTransportationTechnology",
    },
    {
      imageSrc: regionalDesign,
      category: "OPTIMIZE EXISTING INFRASTRUCTURE",
      title: "Generative Regional Design - Different Aspects",
      url: "/blog/GenerativeRegionalDesign",
    },
    {
      imageSrc: bizcase,
      category: "OPTIMIZE EXISTING INFRASTRUCTURE",
      title: "The Business Case - Hypertransit System",
      url: "/blog/TheBusinessCase",
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle style={{color: "#04A0D2"}}>Popular Blog Posts</HeadingTitle>
          <HeadingDescription>Some amazing blog posts about Hypertransit.</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Link href={post.url} style={{color: "#337AB7"}}>Read Post</Link>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};
