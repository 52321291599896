import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingLg } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import topBlogImage from "images/topblog.png";
import optimizeInfra from "images/optimizeInfra.png";
import regionalDesign from "images/regionalDesign.png";
import bizcase from "images/bizcase.png";


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-blue-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-blue-600 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-blue-700 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Blog Posts",
  posts = [
    {
      imageSrc: topBlogImage,
      category: "APPLES TO APPLES COMPARISON",
      date: "December 9, 2021",
      title: "Existing Infrastructure and Transportation Technology",
      description:
        "The Hypertransit system has the opportunity to effectively replace all flights over land and drastically reduce daily commuting via personal vehicle. This is possible through a growing Hypertransit infrastructure network via Hyper-trains, existing trains, and electric shuttles.",
      url: "/blog/ExistingInfraAndTransportationTechnology",
      featured: true
    },
    {
      imageSrc: optimizeInfra,
      category: "OPTIMIZE EXISTING INFRASTRUCTURE",
      date: "December 9, 2021",
      title: "New System Integration and Backwards Compatibility",
      description:
        "Drive new high-speed Hypertrains on Existing Railway Infrastructure to Increase System Accessibility and Convenience",
      url: "/blog/OptimizeExistingInfra",
      featured: false
    },
    {
      imageSrc: regionalDesign,
      category: "OPTIMIZE EXISTING INFRASTRUCTURE",
      date: "December 9, 2021",
      title: "Generative Regional Design - Different Aspects",
      url: "/blog/GenerativeRegionalDesign",
      featured: false
    },
    {
      imageSrc: bizcase,
      category: "OPTIMIZE EXISTING INFRASTRUCTURE",
      date: "December 9, 2021",
      title: "The Business Case - Hypertransit System",
      url: "/blog/TheBusinessCase",
      featured: false
    },
    // getPlaceholderPost(),
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <div>
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingLg>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Posts>
              {posts.slice(0, visible).map((post, index) => (
                <PostContainer key={index} featured={post.featured}>
                  <Post className="group" as="a" href={post.url}>
                    <Image imageSrc={post.imageSrc} style={{border:"2px solid gray", borderColor:"rgba(229, 231, 235, 1"}}/>
                    <Info>
                      <Category>{post.category}</Category>
                      <CreationDate>{post.date}</CreationDate>
                      <Title>{post.title}</Title>
                      {post.featured && post.description && <Description>{post.description}</Description>}
                    </Info>
                  </Post>
                </PostContainer>
              ))}
            </Posts>
            {visible < posts.length && (
              <ButtonContainer>
                <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
              </ButtonContainer>
            )}
          </ContentWithPaddingLg>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </div>
    
  );
};

// const getPlaceholderPost = () => ({
//   imageSrc:
//     "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
//   category: "Travel Guide",
//   date: "April 19, 2020",
//   title: "Visit the beautiful Alps in Switzerland",
//   description:
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//   url: "https://reddit.com"
// });
