import * as React from "react";
import { Popover, OverlayTrigger } from 'react-bootstrap';
// import { useState } from "react";

function Popovers(props) {

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h2">{props.title}</Popover.Title>
      <Popover.Content>
        {props.content}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" overlay={popover}>
      <button className="popover-btn" id={props.btnId}></button>
    </OverlayTrigger>
  );
  
};

export default Popovers;