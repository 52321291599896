import * as React from "react";
import { Collapse } from 'react-bootstrap';
import { useState } from "react";

const Expand = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button className="expand-button"
        aria-label="expand-btn"
        onClick={() => setOpen(!open)}
        aria-controls="collapse-text"
        aria-expanded={open}
      >
      </button>
      <Collapse in={open}>
        <div id="collapse-text">
          {props.text}
        </div>
      </Collapse>
    </>
  );
}

export default Expand;