import * as React from "react";
import { Carousel } from 'react-bootstrap';
import render1 from "../images/render1.jpg"
import render2 from "../images/render2.jpg"
import render3 from "../images/render3.jpg"
import render4 from "../images/interior-with-pod.jpg"

const MyCarousel = () => {

  return (
    <>
      <Carousel fade style={{ maxWidth:"1200px", margin:"0px auto" }}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={render4}
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={render2}
            alt="Second slide"
          />
          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={render3}
            alt="Third slide"
          />
          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={render1}
            alt="Fourth slide"
          />
          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default MyCarousel;