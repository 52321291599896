import * as React from "react";
import greenRoof1 from "../images/1 Offsite Module Construction.jpg";
import greenRoof2 from "../images/2 Offsite Module Aggregation for Transportation.jpg";
import greenRoof3 from "../images/3 On Site Assembly.jpg";
import greenRoof4 from "../images/4 Recyclable Vegetation Planting.jpg";

function GRSection() {

  return (
    <>
      <section className="station-render-section" style={{ backgroundColor:"white" }} id="mpgrd">
        <div className="building-header" style={{ paddingTop:"35px" }}>
          <h1>Modular Prefabricated Green Roof Design</h1>
          <p>As part of the hyperloop station's green roof, this easily-producible hexagon module using salvaged materials such as city recyclables and wood byproducts can be completed by community volunteering efforts. Those modules are then aggregated into composites of transportable insulated roofing prefab portions.</p>
        </div>
      </section>
      <div className="building-overview-header" style={{ padding:"10px 20px" }}>
        <h2>Offsite Module Construction</h2>
      </div>
      <img src={greenRoof1} alt="greenRoof1" id="greenRoof1" style={{ margin:"10px 0 0" }}></img>
      <div className="building-overview-header" style={{ padding:"10px 20px" }}>
        <h2>Offsite Module Aggregation for Transportation</h2>
      </div>
      <img src={greenRoof2} alt="greenRoof2" id="greenRoof2" style={{ margin:"0 0 10px 0" }}></img>
      <div className="building-overview-header" style={{ padding:"10px 20px" }}>
        <h2>On Site Assembly</h2>
      </div>
      <img src={greenRoof3} alt="greenRoof3" id="greenRoof3" style={{ margin:"10px 0" }}></img>
      <div className="building-overview-header" style={{ padding:"10px 20px" }}>
        <h2>Recyclable Vegetation Planting</h2>
      </div>
      <img src={greenRoof4} alt="greenRoof4" id="greenRoof4" style={{ margin:"10px 0 60px 0" }}></img>
    </>
  );
  
};

export default GRSection;