import React from 'react';
import { useParams } from 'react-router-dom';
// import AnimationRevealPage from "helpers/AnimationRevealPage.js"

import ExistingInfraComparison from "pages/ExistingInfraComparison.js";
import OptimizeExistingInfra from "pages/OptimizeExistingInfra.js";
import GenerativeRegionalDesign from "pages/GenerativeRegionalDesign.js";
import TheBusinessCase from "pages/TheBusinessCase.js";


export const blog = {

    ExistingInfraAndTransportationTechnology: {
      component: ExistingInfraComparison,
    },
    OptimizeExistingInfra: {
      component: OptimizeExistingInfra,
    },
    GenerativeRegionalDesign: {
      component: GenerativeRegionalDesign,
    },
    TheBusinessCase: {
      component: TheBusinessCase,
    }
}

export default () => {
  // eslint-disable-next-line
  const { type, subtype, name } = useParams()

  try {
    let Component = null;
    // if(type === "blocks" && subtype) {
    //   Component= blog[type][subtype]["elements"][name].component
    //   return <AnimationRevealPage disabled>
    //       <Component/>
    //     </AnimationRevealPage>
    // }
    // else
    Component= blog[name].component

    if(Component)
      return <Component/>

    throw new Error("Component Not Found")
  }
  catch (e) {
    console.log(e)
    return <div>Error: Component Not Found</div>
  }
}
