import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import existingInfra from "images/existingInfra.png";
import timeComp from "images/timeComp.png";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Existing Infrastructure and Transportation Technology" }) => {
  return (
    <>
      <AnimationRevealPage>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading className="mb-4">{headingText}</Heading>
            </HeadingRow>
            <Text>

              <p>Last updated: December 9, 2021</p>

              <h1>EXISTING INFRASTRUCTURE + PROPOSED HYPERWAY</h1>
              <img src={existingInfra} alt="EXISTING INFRASTRUCTURE"></img>
              
              <h1>TRANSPORTATION SYSTEMS - PRODUCTIVE TIME + EMISSIONS</h1>
              <img src={timeComp} alt="TRANSPORTATION SYSTEMS Time Comparison"></img>
              <p>The Hypertransit system has the opportunity to effectively replace all flights over land and drastically reduce daily commuting via personal vehicle. This is possible through a growing Hypertransit infrastructure network via Hyper- trains, existing trains, and electric shuttles.</p>
              <p>source: viarail.ca</p>
              <p>*source: <a target="_blank" href="https://www.less.ca/en-ca/flights.cfm?auid=ac7" rel="noreferrer">https://www.less.ca/en-ca/flights.cfm?auid=ac7</a></p>
          
              <h1>THE AUTOMOBILE</h1>
              <p>
                Canadian Urban Planning is dominated by road infrastructure with more than 1 million kilometres of roads with 40% of the network paved and 60% unpaved. Four provinces - Ontario, Quebec, Saskatchewan, and Alberta account for over 75% of the total road length. <u>Emissions from passenger cars and light trucks accounting for approximately 25% of Canada’s total greenhouse gas emissions (GHG).</u>
              </p>
              <p>
                The United States spends $100B per year in constructing new roads and bridges and another $100B each year in maintaining roads and bridges.
              </p>
              <p>
                Beyond the cost of owning a vehicle being greater than $10,000 per year per car, roads that are inade- quately maintained cost Canadian drivers an additional total of $3 billion per year in higher vehicle operating costs.
              </p>
              <p>
                Vehicle prices in Canada average an all-time high of more than $40,000, with the most popular loan term in Canada equalling 84 months or 7 years.
              </p>
              <p>
                Electric vehicles or Autonomous Electric Vehicles (EAV’s) are not the answer to existing highway traf- fic, sustainable travel, productive travel, accessible travel, or affordable travel.
              </p>
            </Text>
          </ContentWithPaddingXl>
        </Container>
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
