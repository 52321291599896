import * as React from "react";

function BBSection() {

  return (
    <section className="BB-section">
      
      <div className="BB-container">
        <div className="BB">
          <div className="all"></div>
          <div className="part-1"></div>
          <div className="part-2"></div>
          <div className="part-3"></div>
          <div className="part-4"></div>
          <div className="part-5"></div>
          <div className="ending"></div>
        </div>
      </div>
      <div className="description-container">
        <div className="description-header">
          <h2>Building Layer Breakdown</h2>
          <p>Scroll the webpage to view breakdown layers of the structure.</p>
        </div>

        <div className="hs-description">
          <h2>GRC Panels</h2>
          <p></p>
        </div>

        <div className="hs-description">
          <h2>Low-E Glass Panels</h2>
          <p></p>
        </div>

        <div className="hs-description">
          <h2>Mass Timber Frame</h2>
          <p></p>
        </div>

        <div className="hs-description">
          <h2>Mass Timber Slabs</h2>
          <p></p>
        </div>

        <div className="hs-description">
          <h2>Concrete Foundation</h2>
          <p></p>
        </div>

        <div className="hs-description">
          <h2>The Whole Building</h2>
          <p></p>
        </div>
      </div>
    </section>
  );
  
};

export default BBSection;